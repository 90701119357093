<template>
  <div>
    <div class="dashboard-wraper mt-3">
      <router-link
        :to="{ name: 'add-agency' }"
        class="btn btn-primary btn-sm float-end"
        ><i class="lni lni-plus me-2"></i>Add New</router-link
      >
      <h4>My Agencies</h4>
      <table class="table table-striped" v-if="! loading">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agency, i) in agencies" :key="`agency-${i}`">
            <td>{{ i + 1 }}</td>
            <td>
              <img :src="agency.logo" :alt="agency.name" height="50px" class="me-3">
              {{ agency.name }}
            </td>
            <td>{{ agency.email }}</td>
            <td>{{ agency.phone_number }}</td>
            <td align="right">
              <router-link
                class="btn btn-info btn-sm me-2"
                :to="{
                  name: 'page',
                  params: { slug: agency.slug },
                }"
                ><i class="lni lni-eye me-2"></i>Details</router-link
              >
              <router-link
                class="btn btn-warning btn-sm"
                :to="{
                  name: 'my-account.agency.show',
                  params: { id: agency.id },
                }"
                ><i class="lni lni-pencil"></i></router-link
              >
            </td>
          </tr>
          <tr v-if="agencies.length == 0">
            <td colspan="5">There is no data to display</td>
          </tr>
        </tbody>
      </table>
      <loading v-if="loading" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      agencies: [],
      loading: true
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/my-account").then((response) => {
        this.loading = false;
        this.agencies = response.data.agencies;
      });
    },
  },
};
</script>
